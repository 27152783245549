import { createContext, useRef } from "react";

interface RevealPrototypeType {
  experienceRef: React.RefObject<HTMLDivElement>;
  projectsRef: React.RefObject<HTMLDivElement>;
  technologyRef: React.RefObject<HTMLDivElement>;
  scrollHandler: EventListener;
}

interface RevealProviderProps {
  children: React.ReactNode;
}

export const RevealContext = createContext<RevealPrototypeType | undefined>(
  undefined
);

const RevealContextProvider: React.FC<RevealProviderProps> = ({ children }) => {
  const experienceRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);
  const technologyRef = useRef<HTMLDivElement>(null);

  const sectionRefs: React.RefObject<HTMLDivElement>[] = [
    experienceRef,
    projectsRef,
    technologyRef,
  ];

  function scrollHandler() {
    if (window.innerWidth > 768) return; //prevent running event listener on larger screens

    sectionRefs.forEach((sectRef) => {
      if (!sectRef) return;

      var windowHeight = window.innerHeight;
      var revealTop = sectRef.current?.getBoundingClientRect().top;
      var revealPoint = 50;

      if (!revealTop) return;

      if (revealTop < windowHeight - revealPoint) {
        sectRef.current?.classList.add("active-reveal-section");
        return;
      }

      sectRef.current?.classList.remove("active-reveal-section");
    });
  }

  window.addEventListener("scroll", scrollHandler, { passive: true });

  return (
    <RevealContext.Provider
      value={{ experienceRef, projectsRef, technologyRef, scrollHandler }}
    >
      {children}
    </RevealContext.Provider>
  );
};

export default RevealContextProvider;
