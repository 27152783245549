import React from "react";
import Intro from "./headerComponents/Intro";
import Navigation from "./headerComponents/Navigation";
import Socials from "./headerComponents/Socials";

function HeadNav() {
  return (
    <header className="lg:w-2/5 lg:h-max flex flex-col lg:sticky lg:top-0 py-8 px-3">
      <Intro />

      <Navigation />

      <Socials />
    </header>
  );
}

export default HeadNav;
