import React, { useContext } from "react";
import Languages from "./technologyComponents/Languages";
import Frameworks from "./technologyComponents/Frameworks";
import { RevealContext } from "../RevealContext";

function Technology() {
  const revealRefs = useContext(RevealContext);

  return (
    <section
      id="technologySection"
      className="content-section reveal-section"
      ref={revealRefs?.technologyRef}
    >
      <div className="mobile-heading">
        <h1 className="text-xl text-white font-semibold">TECHNOLOGY</h1>
      </div>

      <Languages />

      <Frameworks />
    </section>
  );
}

export default Technology;
