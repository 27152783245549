import React from "react";
import ghLogo from "../../assets/github-logo.svg";
import linkedLogo from "../../assets/linkedin-logo.svg";
// import gmailLogo from "../../assets/gmail-logo.svg";
import tryhackmeLogo from "../../assets/tryhackme.svg";
import leetcodeLogo from "../../assets/leetcode-colored.svg";
import protonmailLogo from "../../assets/protonmail-logo.svg";

function Socials() {
  return (
    <section className="flex gap-4 justify-center py-4 lg:m-0 lg:justify-start lg:align-center">
      <a
        href="https://www.github.com/winterrdog"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={ghLogo}
          alt="github"
          className="w-7 h-7 lg:w-8 lg:h-8 hover:scale-125 transition-transform hover:duration-300"
        />
      </a>

      <a
        href="https://www.linkedin.com/in/ssenabulya-joe-joachim-69ab67239/"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={linkedLogo}
          alt="linked_in"
          className="w-7 h-7 lg:w-8 lg:h-8 hover:scale-125 transition-transform hover:duration-300"
        />
      </a>

      {/* <a
        href="mailto:mayaoffsetmatrix@gmail.com"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={gmailLogo}
          alt="linked_in"
          className="w-7 h-7 lg:w-8 lg:h-8 hover:scale-125 transition-transform hover:duration-300"
          title="Send me an email"
        />
      </a> */}

      <a
        href="mailto:jojoachim27@protonmail.ch"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={protonmailLogo}
          alt="linked_in"
          className="w-7 h-7 lg:w-8 lg:h-8 hover:scale-125 transition-transform hover:duration-300"
          title="Send me an email"
        />
      </a>

      <a
        href="https://tryhackme.com/p/winterrdog"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={tryhackmeLogo}
          alt="tryhackme"
          className="w-7 h-7 lg:w-8 lg:h-8 hover:scale-125 transition-transform hover:duration-300"
        />
      </a>

      <a
        href="https://leetcode.com/u/winterrdog/"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={leetcodeLogo}
          alt="leetcode"
          className="w-7 h-7 lg:w-8 lg:h-8 hover:scale-125 transition-transform hover:duration-300"
        />
      </a>
    </section>
  );
}

export default Socials;
