import React from "react";
import HeadNav from "./components/HeadNav";
import Content from "./components/Content";

function App() {
  return (
    <div className="max-w-screen-xl min-h-screen lg:flex lg:px-16 lg:gap-10">
      <HeadNav />
      <Content />
    </div>
  );
}

export default App;
