import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

function Navigation() {
  return (
    <BrowserRouter>
      <nav className="hidden lg:flex lg:flex-col lg:py-4 lg: lg:gap-3 lg:flex-auto lg:flex-shrink-0 lg:mb-12">
        <Link className="navigation-link" to="#summarySection" smooth>
          ABOUT
        </Link>
         
        <Link className="navigation-link" to="#experienceSection" smooth>
          EXPERIENCE
        </Link> 

        <Link className="navigation-link" to="#projectsSection" smooth>
          PROJECTS
        </Link>

        <Link className="navigation-link" to="#technologySection" smooth>
          TECHNOLOGY
        </Link>
      </nav>
    </BrowserRouter>
  );
}

export default Navigation;
