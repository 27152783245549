import React, { useContext } from "react";
import uniqid from "uniqid";
import externalLink from "./images/externalLink.svg";
import codeCircle from "./images/codeCircle.svg";

import mickChatImg from "../../assets/projectImages/mick-chat.png";
import trieBlog from "../../assets/projectImages/trie-blog.png";
import byobImg from "../../assets/projectImages/byob.png";
import fudUuidImg from "../../assets/projectImages/fud-uuid.png";
import milliEditorImg from "../../assets/projectImages/milli-editor.png";
import sqlyteDbImg from "../../assets/projectImages/sqlyte-db.png";
import jshImg from "../../assets/projectImages/jsh.png";
import chosenChatApp from "../../assets/projectImages/chosen-chat-app.png";
import leechyImg from "../../assets/projectImages/leechy-torrent.png";
import uslImg from "../../assets/projectImages/usl-game.png";

import myProjects from "./ProjectsComponents/myProjects.json";
import { RevealContext } from "../RevealContext";

const { projects } = myProjects;

const projectImages = {
  mickChat: mickChatImg,
  trieBlog: trieBlog,
  byob: byobImg,
  fudUuid: fudUuidImg,
  milliEditor: milliEditorImg,
  sqlyteDb: sqlyteDbImg,
  jsh: jshImg,
  leechy: leechyImg,
  uslGame: uslImg,
  chosenChatApp,
};

function Projects() {
  const revealRefs = useContext(RevealContext);

  return (
    <section
      id="projectsSection"
      className="content-section reveal-section"
      ref={revealRefs?.projectsRef}
    >
      <div className="mobile-heading">
        <h1 className="text-xl text-white font-semibold">PROJECTS</h1>
      </div>

      {projects &&
        projects.map((project, pjIndex) => {
          console.log();
          return (
            <div
              key={uniqid()}
              className="project-div flex flex-col-reverse gap-4 lg:grid lg:grid-cols-9 lg:gap-4 p-2 lg:hover:bg-slate-800 lg:hover:bg-opacity-80 rounded-md lg:hover:shadow-xl"
            >
              <div className="col-span-3 p-8 lg:p-0">
                <img
                  className={`border-2 rounded border-slate-400 ${
                    pjIndex % 2 === 0
                      ? "rotate-2 lg:rotate-3"
                      : "-rotate-2 lg:-rotate-3"
                  } hover:rotate-0 lg:origin-right transition-transform lg:z-20 lg:hover:duration-300`}
                  src={
                    projectImages[
                      project.imageName as keyof typeof projectImages
                    ]
                  }
                  alt={project.imageName}
                />
              </div>

              <div className="flex flex-col gap-2 col-span-6">
                <a
                  className="text-white flex gap-2 items-center"
                  href={project.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="text-lg relative before:absolute before:bottom-0 before:rounded-md before:h-[2px] before:w-0 before:bg-white before:transition-[width] before:hover:w-full">
                    {project.name}
                  </h2>
                  <img className="w-5 h-5" src={externalLink} alt="🔗" />
                </a>

                <div className="text-sm flex flex-col gap-2">
                  {project.desc &&
                    project.desc.map((projectDesc) => {
                      return <p key={uniqid()}>{projectDesc}</p>;
                    })}
                </div>

                <a
                  className="text-white flex gap-2 items-center"
                  href={project.sourceLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-md relative before:absolute before:bottom-0 before:rounded-md before:h-[2px] before:w-0 before:bg-white before:transition-[width] before:hover:w-full">
                    Source
                  </p>
                  <img className="w-5 h-5" src={codeCircle} alt="🔗" />
                </a>
              </div>
            </div>
          );
        })}
    </section>
  );
}

export default Projects;
