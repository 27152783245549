//import all images
import htmlLogo from "./images/htmlLogo.svg";
import cssLogo from "./images/cssLogo.svg";
import tsLogo from "./images/tsLogo.svg";
import goLangLogo from "./images/go-svgrepo-com.svg";
import pythonLogo from "./images/pythonLogo.svg";
import rustLogo from "./images/rust-lang-ar21.svg";
import cppLogo from "./images/cppLogo.svg";
import cLogo from "./images/c-again.svg";
import jsLogo from "./images/jsLogo.svg";

function Languages() {
  return (
    <div>
      <h2 className="text-white p-2">Languages I am able to speak</h2>

      <div className="flex gap-4 flex-wrap my-4 p-2 justify-center lg:justify-start">
        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={htmlLogo}
          alt="HTML5"
          title="HTML5"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={cssLogo}
          alt="CSS"
          title="CSS"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={jsLogo}
          alt="JS"
          title="JavaScript"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={tsLogo}
          alt="TS"
          title="TypeScript"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={goLangLogo}
          alt="GO"
          title="Golang"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={pythonLogo}
          alt="Py"
          title="Python"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={rustLogo}
          alt="Rust"
          title="Rust"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={cLogo}
          alt="C"
          title="C"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={cppLogo}
          alt="CPP"
          title="Cpp"
        />
      </div>
    </div>
  );
}

export default Languages;
