import React, { useContext } from "react";
import codeBracket from "../../assets/codeBracket.svg";
import experiencesObject from "./ExperienceComponents/experiences.json";
import { RevealContext } from "../RevealContext";

const { experiences } = experiencesObject;

function Experience() {
  const revealRefs = useContext(RevealContext);

  const renderedExperiences = experiences.map((expObj, index) => {
    const renderedDuties = expObj.duties.map((duty, dutyIndex) => {
      return (
        <div key={`duty${dutyIndex}`} className="flex gap-2">
          <img className="w-4 h-4" src={codeBracket} alt="*" />
          <p>{duty}</p>
        </div>
      );
    });

    return (
      <div
        key={index}
        className="experience-div lg:transition-all lg:grid lg:grid-cols-9 lg:gap-2 p-2 lg:hover:bg-slate-800 lg:hover:bg-opacity-80 rounded-md lg:hover:shadow-xl"
      >
        <div className="experience-period col-span-3 lg:origin-right">
          {expObj.period}
        </div>

        <div className="flex flex-col gap-2 col-span-6">
          <h2 className="text-white ">
            {expObj.role} | {expObj.company}
          </h2>

          <div className="text-sm flex flex-col gap-2">
            {expObj.duties && renderedDuties}
          </div>
        </div>
      </div>
    );
  });

  return (
    <section
      id="experienceSection"
      className="content-section reveal-section"
      ref={revealRefs?.experienceRef}
    >
      <div className="mobile-heading">
        <h1 className="text-xl text-white font-semibold">EXPERIENCE</h1>
      </div>

      {experiences && renderedExperiences}
    </section>
  );
}

export default Experience;

/*
  More experience to add to the experiences.json file:
    {
      "period": "Nov 2023 - Mar 2024",
      "role": "Apprentice Backend Engineer",
      "company": "Bixxxy",
      "duties": [
        "Containerized the application with Docker and managed deployment through Kubernetes, optimizing resource utilization and performance.",
        "Automated tests using Jest to guarantee the reliability and functionality of the application.",
        "Maintained the backend features in TypeScript, utilizing NestJS and ExpressJS frameworks, to ensure high-performance, maintainable code.",
        "Utilized Sequelize and Mongoose to control PostgreSQL and MongoDB databases, managing data storage and retrieval efficiently.",
        "Set up GitHub Actions for CI/CD, improving the development and deployment workflow.",
        "Implemented Redis for caching to optimize application performance.",
        "Performed system administration on Debian Linux machines in the AWS cloud, ensuring the reliability and security of infrastructure.",
        "Implemented strong API security measures to protect sensitive data and maintain compliance."
      ]
    }
*/
