import React from "react";

//import all images
import expressJsLogo from "./images/frameworkImages/expressjs-ar21.svg";
import dockerLogo from "./images/frameworkImages/docker-official.svg";
import githubLogo from "./images/frameworkImages/github-tile.svg";
import postgresqlLogo from "./images/frameworkImages/postgres.svg";
import jestLogo from "./images/frameworkImages/jestLogo.svg";
import nestLogo from "./images/frameworkImages/nestjs-ar21.svg";
import awsLogo from "./images/frameworkImages/aws-svgrepo-com.svg";
import mongoDbLogo from "./images/frameworkImages/mongodb-ar21.svg";
import windowsLogo from "./images/frameworkImages/windows-applications-svgrepo-com.svg";
import gitLogo from "./images/frameworkImages/gitLogo.svg";
import linuxLogo from "./images/frameworkImages/linuxLogo.svg";
import jiraLogo from "./images/frameworkImages/atlassian_jira-icon.svg";
import nginxLogo from "./images/frameworkImages/nginx-ar21.svg";
import apache2Logo from "./images/frameworkImages/apache-svgrepo-com.svg";
import redisLogo from "./images/frameworkImages/redis-icon.svg";
import postManLogo from "./images/frameworkImages/getpostman-icon.svg";
import nodeJsLogo from "./images/frameworkImages/nodejs-icon.svg";
import googleCloudLogo from "./images/frameworkImages/google-cloudLogo.svg";

function Frameworks() {
  return (
    <div>
      <h2 className="text-white p-2">
        Frameworks, Tools & Cloud Platforms I've currently worked with
      </h2>

      <div className="flex gap-4 flex-wrap my-4 p-2 justify-center lg:justify-start">
        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={nodeJsLogo}
          alt="Node"
          title="NodeJS"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={jiraLogo}
          alt="Atlassian Jira"
          title="Atlassian Jira"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={postManLogo}
          alt="Postman"
          title="Postman"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={apache2Logo}
          alt="Apache2 Server"
          title="Apache2 Server"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={nginxLogo}
          alt="Nginx"
          title="Nginx"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={expressJsLogo}
          alt="ExpressJS"
          title="ExpressJS"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={nestLogo}
          alt="NestJS"
          title="NestJS"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={dockerLogo}
          alt="Docker"
          title="Docker"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={githubLogo}
          alt="GitHub & Github Actions"
          title="GitHub & Github Actions"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={awsLogo}
          alt="AWS"
          title="AWS"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={googleCloudLogo}
          alt="Google Cloud"
          title="Google Cloud"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={postgresqlLogo}
          alt="PostgreSQL"
          title="PostgreSQL"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={mongoDbLogo}
          alt="MongoDB"
          title="MongoDB"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={redisLogo}
          alt="Redis"
          title="Redis"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={jestLogo}
          alt="Jest"
          title="Jest"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={gitLogo}
          alt="Git"
          title="Git"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={windowsLogo}
          alt="Microsoft Windows"
          title="Microsoft Windows"
        />

        <img
          className="w-16 h-16 transition-transform hover:scale-125 hover:duration-300"
          src={linuxLogo}
          alt="Linux"
          title="Linux"
        />
      </div>
    </div>
  );
}

export default Frameworks;
