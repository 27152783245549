import React from "react";
import Summary from "./contentComponents/Summary";
import Experience from "./contentComponents/Experience";
import Projects from "./contentComponents/Projects";
import Technology from "./contentComponents/Technology";

// import downloadImg from "./contentComponents/images/downloadImg.svg";

function Content() {
  return (
    <main className="lg:w-3/5 py-16 lg:py-24 lg:flex lg:flex-col">
      <Summary />

      <Experience />

      <Projects />

      <Technology />

      {/* <section className="flex justify-center p-2">
        <a
          className="flex gap-2"
          href="/resume/flagResume-Ssenabulya-Joe-Joachim.pdf"
          target="_blank"
          rel="noreferrer"
          download
        >
          <h2 className="text-lg relative before:absolute before:bottom-0 before:rounded-md before:h-[2px] before:w-0 before:bg-white before:transition-[width] before:hover:w-full">
            Download my full Résumé
          </h2>
          <img className="w-6 h-6" src={downloadImg} alt="🔗" />
        </a>
      </section> */}
    </main>
  );
}

export default Content;
