import React from "react";
import profileImg from "../../assets/profilePic/1701158883760.jpeg";

function Intro() {
  return (
    <section className="flex flex-col gap-2 items-center lg:flex lg:flex-col lg:items-start lg:gap-2 lg:mb-8">
      <img
        className="rounded-full h-32 w-32 md:h-60 md:w-60 lg:ml-10"
        src={profileImg}
        alt="Ssenabulya Joe Joachim"
      />
      <h1 className="text-4xl text-center font-bold lg:text-left text-[#DBFE87]">
        Ssenabulya Joe Joachim
      </h1>
      <h2 className="text-xl text-center lg:text-left font-semibold text-[#57B8FF]">
        Backend Engineer( TS | NodeJS | Golang ) || Security Engineer( API
        Security )
      </h2>

      <p className="text-center lg:text-left">
        I create exceptional web backend systems through <i>critical</i>
        -critical thinking.
      </p>
    </section>
  );
}

export default Intro;
